//Translations.js

const translations = {
  "Admin": "مدير",
  "Editor": "مشرف",
  "User": "مستخدم",
  "5150": "مدير",
  "1984": "مشرف",
  "2001": "مستخدم",
  "cash": "نقد",
  "partial": "جزئي / آجل",
  "installments": "اقساط",
};

export default translations;
