import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function ProtectedRoute({ element, roles = [], ...rest }) {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const currentTime = Date.now() / 1000; // الحصول على الوقت الحالي بالثواني

  if (!token) {
    // إعادة توجيه إلى صفحة الدخول إذا لم يوجد token
    return (
      <Navigate
        to="/login"
        state={{ from: location, message: "Please log in to continue." }}
      />
    );
  }
  let decoded;
  try {
    decoded = jwt_decode(token);
    // فحص صلاحية الـ token
    if (!decoded.exp || decoded.exp < currentTime) {
      return (
        <Navigate
          to="/login"
          state={{
            from: location,
            message: "Session has expired, please log in again.",
          }}
        />
      );
    }

    if (!decoded.UserInfo || !decoded.UserInfo.roles) {
      // فحص إذا كان الـ token غير صحيح
      return (
        <Navigate
          to="/login"
          state={{
            from: location,
            message: "Invalid token, please log in again.",
          }}
        />
      );
    }

    if (
      roles.length &&
      !roles.some((role) => decoded.UserInfo.roles.includes(parseInt(role)))
    ) {
      // فحص الصلاحيات
      return (
        <Navigate
          to="/login"
          state={{ from: location, message: "Unauthorized access" }}
        />
      );
    }
  } catch (err) {
    // عند وجود خطأ في فك تشفير الـ token
    return (
      <Navigate
        to="/login"
        state={{
          from: location,
          message: "Error processing your authentication.",
        }}
      />
    );
  }

  const elementWithRoles = React.cloneElement(element, {
    userRoles: decoded.UserInfo.roles,
  });
  return elementWithRoles;
}
