import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const CenteredProgressWhite = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
      <CircularProgress color="inherit" />
    </Box>
  );
};

export const CenteredProgressGreen = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
      <CircularProgress color="success" />
    </Box>
  );
};

export const ProgressGreen = ({ sx }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={sx}>
      <CircularProgress color="success" />
    </Box>
  );
};
